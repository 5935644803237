import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import PageCarousel from "../components/PageCarousel"
import { useStaticQuery, graphql } from "gatsby"

export default function Chainsaws() {
  const imageData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "images/chainsaws" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>Chainsaw servicing | Service Center</title>
        <meta
          name="description"
          content="Keeping your chainsaw clean and regularly serviced will make it safer to use, but it will also prolong the chainsaw&#x2019;s life."
        />
      </Helmet>

      <Layout title="Chainsaws">
        <section className="overflow-hidden">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div>
                  <p>We can fix and tune almost any type of chainsaw.</p>
                  <p>
                    We recommend keeping your chainsaw regularly serviced to
                    make it safer to use and to extend its life.
                  </p>
                  <p>A general service may include:</p>
                  <ul>
                    <li>cleaning the air filter cooling inlets</li>
                    <li>checking the bar for straightness, burring and wear</li>
                    <li>replacing the sprocket and chain</li>
                    <li>sharpening the chain</li>
                  </ul>
                  <p>
                    If you need to upgrade, we have a wide range of chainsaws
                    available to suit most types of applications. Come in and
                    speak with our team of experts and get some advice on which
                    saw is right for you.
                  </p>
                  <p>
                    <em>We are now stockists of Oleo-Mac.</em>
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <PageCarousel imageData={imageData} />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
